"use strict";
// TODO handle anchor tag links: https://github.com/visionmedia/page.js/blob/4f9991658f9b9e3de9b6059bade93693af24d6bd/page.js#L745
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
var lit_1 = require("lit");
var unsafe_html_1 = require("lit/directives/unsafe-html");
window.notif = function (msg, type) {
    if (type === void 0) { type = 'info'; }
    console.log('Notification', type, msg);
    var notifEl = document
        .querySelector('c-notify');
    notifEl.show(msg, type);
};
var Router = /** @class */ (function () {
    function Router(_this, routes, defaultTitle, titleSuffix) {
        if (titleSuffix === void 0) { titleSuffix = ''; }
        this._titleSuffix = '';
        this._defaultTitle = '';
        this.updatePage = this.updatePage.bind(_this);
        this.navigate = this.navigate.bind(this);
        window.navigate = this.navigate;
        this._routes = routes;
        this.previousContent = lit_1.html(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
        this._defaultTitle = defaultTitle;
        this._titleSuffix = titleSuffix;
    }
    Router.prototype.navigate = function (path, dontPushState, scrollPosition) {
        if (dontPushState === void 0) { dontPushState = false; }
        if (scrollPosition === void 0) { scrollPosition = 0; }
        return __awaiter(this, void 0, void 0, function () {
            var pathname, split, pStr, pArray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        window.showLoading();
                        pathname = new URL(path, window.location.origin).pathname;
                        if (!Object.prototype.hasOwnProperty.call(this._routes, pathname)) {
                            console.log("Route " + pathname + " not defined");
                            window.notif("Page not found");
                            window.hideLoading();
                            return [2 /*return*/];
                        }
                        // Check Authorized
                        if (this._routes[pathname].protected) {
                            if (!localStorage.logged_in) {
                                window.notif('Please log in');
                                // TODO handle url to redirect to after login
                                window.redirect = path;
                                window.navigate('/login');
                                return [2 /*return*/];
                            }
                        }
                        // // if url is just '/' and signed in, redirect to '/home'
                        if (path === '/' &&
                            window.location.search === '' &&
                            localStorage.logged_in) {
                            window.navigate('/', true);
                            return [2 /*return*/];
                        }
                        // Don't push state if reloading
                        if (path === window.currentUrl) {
                            dontPushState = true;
                        }
                        // Set global url prop
                        window.currentUrl = path;
                        // Push new page to history, unless navigating back
                        if (!dontPushState) {
                            // keep track of pages to handle going back nicely
                            window.history.stack = window.history.stack || [];
                            window.history.stack.push(path); // use the url WITH query params
                            // Pointer to current page in stack
                            window.history.stackPointer = window.history.stack.length;
                            // TODO vanilla scrollTop
                            // window.history.pushState(
                            //   { scrollPos: $(window).scrollTop() },
                            //   '',
                            //   pageUrl
                            // )
                            window.history.pushState({ scrollPos: 0 }, '', path);
                        }
                        split = path.split('?');
                        path = split[0];
                        window.params = {};
                        if (split[1]) {
                            pStr = split[1];
                            pArray = pStr.split('&');
                            pArray.forEach(function (param) {
                                var pSplit = param.split('=');
                                window.params[pSplit[0]] = decodeURI(pSplit[1]);
                            });
                        }
                        if (Object.prototype.hasOwnProperty.call(this._routes[path], 'params'))
                            window.params = __assign(__assign({}, window.params), this._routes[path].params);
                        // Import the component so it is defined - should be defined in global scope
                        return [4 /*yield*/, this.loadComponent(path)
                            // TODO vanilla
                            // $(window).scrollTop(scrollPosition)
                        ];
                    case 1:
                        // Import the component so it is defined - should be defined in global scope
                        _a.sent();
                        // TODO vanilla
                        // $(window).scrollTop(scrollPosition)
                        window.hideLoading();
                        return [2 /*return*/];
                }
            });
        });
    };
    Router.prototype.loadComponent = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!!customElements.get(this._routes[url].component)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._routes[url]["import"]()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.updatePage(this._routes[url].component);
                        // Set page title
                        document.title = this._routes[url].title
                            ? "" + this._routes[url].title + this._titleSuffix
                            : this._defaultTitle;
                        return [2 /*return*/];
                }
            });
        });
    };
    Router.prototype.updatePage = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.viewContent = lit_1.html(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
                        this.requestUpdate();
                        return [4 /*yield*/, this.updateComplete];
                    case 1:
                        _a.sent();
                        this.viewContent = unsafe_html_1.unsafeHTML("<" + data + "></" + data + ">");
                        return [2 /*return*/];
                }
            });
        });
    };
    return Router;
}());
exports["default"] = Router;
// Handle going back in browser
window.onpopstate = function (e) {
    if (e.state) {
        e.preventDefault();
    }
    // TODO do something with this or remove it
    window.history.stackPointer--;
    window.navigate(window.location.pathname + window.location.search, true, (e.state || 0).scrollPos || 0);
    /* TODO use window.history.stack so that when navigating back from code,
          if the last page is not on this website (stack size == 0), go to home page
          navigating back with the back button should still allow going back to a different website
          */
};
// When a toUrl is passed, it will call history.back() if you've come from that url, else it will navigate() to that url
window.back = function (toUrl) {
    /*
      toUrl		stack		!=-2
          0				0							'/'
          0				1							back
          1				0							toUrl
          1				1				0			back
          1				1				1			toUrl
      */
    // Go back if there is a stack and no toUrl, or if the toUrl is the same as the previous url
    if (Object.prototype.hasOwnProperty.call(window.history, 'stack') &&
        (!toUrl || toUrl === window.history.stack[window.history.stack.length - 2])) {
        window.history.back();
        return;
    }
    // Navigate to toUrl if there is one and no stack, or the previous page is not the same as the toUrl
    if (toUrl &&
        (!Object.prototype.hasOwnProperty.call(window.history, 'stack') ||
            toUrl !== window.history.stack[window.history.stack.length - 2])) {
        window.navigate(toUrl);
        return;
    }
    // No stack or toUrl, so navigate to home page
    window.navigate('/');
};
// Shows loading overlay
window.showLoading = function () {
    // const el = $('loading-overlay')[0]
    var app = document.querySelector('app-root');
    if (!app)
        return;
    var el = app.shadowRoot.querySelector('loading-overlay');
    if (!el)
        return;
    var lo = el.shadowRoot.querySelector('#loadingOverlay');
    if (!lo)
        return;
    lo.setAttribute('visible', 'true');
    app.classList.add('fadeOut');
    window._navStartTime = Date.now();
};
// Hides loading overlay
window.hideLoading = function () {
    var animTime = 0;
    setTimeout(function () {
        var app = document.querySelector('app-root');
        if (!app)
            return;
        var el = app.shadowRoot.querySelector('loading-overlay');
        if (!el)
            return;
        var lo = el.shadowRoot.querySelector('#loadingOverlay');
        if (!lo)
            return;
        lo.removeAttribute('visible');
        app.classList.remove('fadeOut');
    }, animTime - (Date.now() - window._navStartTime));
};
var templateObject_1, templateObject_2;
