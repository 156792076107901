:host {
  --primary-text: #3d4852;
  --background-primary: #f9f9ff;
  --icon-fill: #3b3f54;
  --icon-fill-hover: #262936;
  --button-primary-background: #242b3a;
  --button-primary-action: #e4ebf8;
  --button-green-background: #7da36c;

  font-family: 'Ubuntu', sans-serif;

  & > * {
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 100%;
    flex-direction: column;
    background: var(--background-primary);
  }
}
